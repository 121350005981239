import React from 'react';
import './services.css'

function ServiceTable() {
    return (
        <div className="servicesContainer" id='services'>
            <div className="services">
                <h1>Services</h1>

                {/* Boarding */}
                <div className="serviceItem">
                    <p>Boarding</p>
                    <p>$45 per night</p>                 
                </div>

                {/* Drop-In Visits */}
                <div className="serviceItem">
                    <p>Drop-In Visits</p>
                    <p>$25 per visit</p>
                </div>

                {/* Doggy Day Care */}
                <div className="serviceItem">
                    <p>Doggy Day Care</p>
                    <p>$32 per day</p>
                </div>

                {/* Dog Walking */}
                <div className="serviceItem">
                    <p>Dog Walking</p>
                    <p>$25 per walk</p>
                </div>

                {/* You can continue adding more services as needed */}
            </div>
        </div>
    );
}

export default ServiceTable;

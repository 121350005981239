import React, { useState } from 'react';
import './contact.css';

const Contact = () => {
    const [formData, setFormData] = useState({
        service: '',
        startDate: new Date().toISOString().slice(0, 10),
        endDate: new Date().toISOString().slice(0, 10),
        startTime: '',
        endTime: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formData);
        // Here you would typically send the data to the server
    };

    return (
        <div className="contact-form-container" id='contact'>
            <h2>Contact Form</h2>
            <form onSubmit={handleSubmit}>
                <div className='serviceContainer'>
                    <label>
                        Service:
                        <select name="service" value={formData.service} onChange={handleChange}>
                            <option value="">Select a service...</option>
                            <option value="service1">Boarding</option>
                            <option value="service2">Drop-In Visits</option>
                            <option value="service3">Doggy Day Care</option>
                            <option value="service4">Dog Walking</option>
                            {/* ...other options */}
                        </select>
                    </label>
                </div>
                <div className='optionsContainer'>
                    <label>
                        Start Date:
                        <input type="date" name="startDate" value={formData.startDate} onChange={handleChange} />
                    </label>
                    <label>
                        End Date:
                        <input type="date" name="endDate" value={formData.endDate} onChange={handleChange} />
                    </label>
                    <label>
                        Start Time:
                        <input type="time" name="startTime" value={formData.startTime} onChange={handleChange} />
                    </label>
                    <label>
                        End Time:
                        <input type="time" name="endTime" value={formData.endTime} onChange={handleChange} />
                    </label>
                </div>
                <button className='contactButton' type="submit">Submit</button>
            </form>
        </div>
    );
}

export default Contact;

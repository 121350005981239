import React, { useState } from 'react';
import './navbar.css';
import logo from '../../assets/logoa.svg';

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleMouseLeave = () => {
    setIsMenuOpen(false);
  };
  const handleScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      window.scrollTo({
        top: element.offsetTop,
        behavior: 'smooth'
      });
    }
  };

  return (
    <>
      <nav className="navbar">
        <div className="navbar__container">
          <img src={logo} alt="Logo" className='navbar__logo' />
          
          <div 
            className={`navbar__links ${isMenuOpen ? 'navbar__links--active' : ''}`}
            onMouseLeave={handleMouseLeave}
          >
            <a onClick={() => handleScroll('aboutContainer')} href="javascript:void(0);">About</a>
            <a onClick={() => handleScroll('services')} href="javascript:void(0);">Services</a>
            <a onClick={() => handleScroll('contact')} href="javascript:void(0);">Contact</a>
            <a onClick={() => handleScroll('reviews')} href="javascript:void(0);">Reviews</a>
          </div>
          
          <div className="navbar__hamburger" onClick={handleMenuClick}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </nav>

      <div className="navbar__info">
        <i className="fa fa-phone"></i> 720.237.0754 | South Hampton | St. Augustine, Florida
      </div>
    </>
  );
}

export default Navbar;

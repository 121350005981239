import React from 'react'
import './header.css';
import girl from './../../assets/girl.png';

const Header = () => (
  <div className="gpt3__header section__padding" id="home">
    <div className="gpt3__header-content">
      <h1 className="gradient__text">Spoil your pups with Casey!</h1>
      
    </div>

    <div className="gpt3__header-image">
      <img src={girl} alt='img'/>
      
    </div>
   
  </div>
);

export default Header;
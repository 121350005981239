import Navbar from './components/Navbar/navbar.js';
import Header from './components/Header/header.js';
import Form from './components/Form/form.js';
import About from './components/About/about.js';
import Services from './components/Services/services.js';
import Contact from './components/Contact/contact.js';
import './App.css';

function App() {
  return (
    <div className="App">
      <Navbar />
      <Header />
      <Form />
      <About />
      <Services />
      <Contact />
    </div>
  );
}

export default App;

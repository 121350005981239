import React from 'react';
import './form.css';

const Form = () => {
  return (
    <div className='Form1'>
      <form className="form">
        {/* Use an anchor tag around the button */}
        <a href="#contact" className="learn-more-btn-anchor">
          <button type="button" className="learn-more-btn">Book Now</button>
        </a>
      </form>
      <div className="new_text">
        <p>Over 100 5 Star Reviews!</p>
      </div>
    </div>  
  );
};

export default Form;

import React from 'react'
import './about.css'

const About = () => {
  return (
    <div className='aboutContainer'> 
        <div className='about' id="aboutContainer">
            <h1>About Casey</h1>
            <p>Animal lover, caretaker, and dog trainer here! With a lifetime of experience and education, I provide excellent quality care for your furry family members. I am a stay at home Mom, and practice a compassionate and gentle approach to caring for others. I do require that your pets get along with small children, since I have 3! My life has been blessed to be full of raising and training animals. My experience, adaptability, and patience create a great experience for your pups when it matters most! Thank you for giving me the opportunity to share my passion by caring for your pups. 🙏</p>
        </div>
    </div>
  )
}

export default About;